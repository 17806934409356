import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { motion } from 'framer-motion'
import { getSettings } from 'src/selectors/settings'

import { StyledLogo } from './Logo.style'

const Logo: React.FC = (props) => {
    const { logo, language } = useSelector(getSettings)

    return (
        <StyledLogo
            as={motion.div}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.15, ease: 'easeOut' }}
        >
            <Link to={`/${language}`}>
                <img src={logo} alt="Logo" />
            </Link>
        </StyledLogo>
    )
}

export default Logo
