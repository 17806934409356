import Container from 'src/components/_layout/Container/Container'
import Button from 'src/components/atoms/Button/Button'
import { Social } from 'src/components/molecules/SiteLinks/SiteLinks.style'
import { styleConstants, styled } from 'src/styles/stitches.config'

export const StyledHeader = styled('div', {
    position: 'fixed',
    top: '0',
    left: 0,
    width: '100%',
    zIndex: 5,
    color: '$green',
    '@md': { top: '$sm' },
    [`${Button}`]: {
        borderColor: '$green',
        color: '$green',
        fontWeight: '$medium',
    },

    '.logo-svg': {
        fill: '$green',
    },

    '&.light': {
        color: '$white',
        [`${Button}`]: {
            borderColor: '$white',
            color: '$white',
            fontWeight: '$medium',
        },

        [`${Social}`]: {
            backgroundColor: '$white',
            color: '$green',
        },

        '.logo-svg': {
            fill: '$white',
        },
    },
})

export const StyledHeaderContent = styled(Container, {
    paddingLeft: '0',
    paddingRight: '0',
    '> div': {
        padding: '$xs $sm',
    },
    button: {
        position: 'relative',
        zIndex: 1,
    },
    '@mdDown': {
        '> div': {
            position: 'relative',
            backgroundColor: '$whiteSmoke',

            '&::before': {
                position: 'absolute',
                top: 0,
                right: 0,
                width: 0,
                height: '100%',
                backgroundColor: '$green',
                transition: 'all 0.734s ease-out 0.4s',
                content: '',
            },
        },
    },
    '@sm': {
        paddingLeft: `calc(${styleConstants.colWidth * 2}vw - $xs)`,
        paddingRight: `calc(${styleConstants.colWidth * 2}vw - $xs)`,

        '> div': {
            padding: '$sm',
            backgroundColor: '$whiteSmoke',
        },
    },

    variants: {
        open: {
            true: {
                '> div::before': {
                    width: '100%',
                    transition: 'all 0.734s ease-out',
                },
            },
        },
    },
})
