import { useSelector } from 'react-redux'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Text from 'src/components/atoms/Text/Text'
import { getSettings } from 'src/selectors/settings'
import { Divider, StyledLanguageSwitcher } from './LanguageSwitcher.style'
const LanguageSwitcher: React.FC = (props) => {
    const { languages } = useSelector(getSettings)
    return (
        <StyledLanguageSwitcher>
            <FlexWrapper css={{ gap: '$xxs', color: '$black' }} align="center">
                {Object.keys(languages).map((key, index) => (
                    <>
                        <Text
                            as={'a'}
                            href={`/${languages[key].language_code}`}
                            // href={`${
                            //     translations &&
                            //     translations[key]
                            //         ? translations[key]
                            //         : `/${languages[key].language_code}`
                            // }`}
                            key={languages[key].id}
                        >
                            {languages[key].language_code.toUpperCase()}
                        </Text>
                        {index < Object.keys(languages).length - 1 && (
                            <Divider />
                        )}
                    </>
                ))}
            </FlexWrapper>
        </StyledLanguageSwitcher>
    )
}

export default LanguageSwitcher
