import {
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import Button from 'src/components/atoms/Button/Button'
import Input from 'src/components/atoms/Input/Input'
import RadioCheckWrapper from 'src/components/atoms/RadioCheckWrapper/RadioCheckWrapper'
import Text from 'src/components/atoms/Text/Text'
import Textarea from 'src/components/atoms/Textarea/Textarea'
import AdultsSelect from 'src/components/molecules/AdultsSelect/AdultsSelect'
import Container from 'src/components/_layout/Container/Container'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import { API_URL } from 'src/constants'
import useLabel from 'src/hooks/useLabel'
import { getSettings } from 'src/selectors/settings'
import { selectStyles, StyledForm, StyledFormField } from './BookingForm.style'

function useQueryParams() {
    const { search } = useLocation()

    return useMemo(() => new URLSearchParams(search), [search])
}

const BookingForm: React.FC = (props) => {
    const label = useLabel()
    const query = useQueryParams()
    const { booking } = useSelector(getSettings)
    const [response, setResponse] = useState<string | undefined>(undefined)
    const [isSending, setIsSending] = useState<boolean>(false)
    const childrenRef = useRef<any>()
    const adultsRef = useRef<any>()
    const checkinRef = useRef() as MutableRefObject<HTMLInputElement>
    const checkoutRef = useRef() as MutableRefObject<HTMLInputElement>
    const [checkinDate, setCheckinDate] = useState<Date>(new Date())
    const [checkoutDate, setCheckoutDate] = useState<Date>(new Date())
    const [vacancyPrice, setVacancyPrice] = useState<number>(
        booking?.price || 0
    )
    const onFormSubmit = useCallback(
        (e) => {
            e.preventDefault()
            if (isSending) {
                return
            }
            setIsSending(true)
            const form = e.target as HTMLFormElement
            const inputs = form.elements
            const data = {
                'check-in': checkinDate?.toLocaleDateString('it'),
                'check-out': checkoutDate?.toLocaleDateString('it'),
                children: childrenRef.current.getValue()[0].value,
                adults: adultsRef.current.getValue()[0].value,
                firstname: (inputs.namedItem('firstname') as HTMLInputElement)
                    .value,
                lastname: (inputs.namedItem('lastname') as HTMLInputElement)
                    .value,
                telephone: (inputs.namedItem('telephone') as HTMLInputElement)
                    .value,
                email: (inputs.namedItem('email') as HTMLInputElement).value,
                notes: (inputs.namedItem('notes') as HTMLInputElement).value,
                privacy: (inputs.namedItem('privacy') as HTMLInputElement)
                    .value,
            }
            fetch(API_URL + '/sendcontact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...data,
                    subject: 'Richiesta dal sito',
                }),
            })
                .then((response) => response.json())
                .then((jsonData) => {
                    setResponse(jsonData.text)
                    setIsSending(false)
                })
        },
        [checkinDate, checkoutDate, isSending]
    )

    const setVacancyDays = useCallback(() => {
        if (!!checkinDate && !!checkoutDate) {
            if (checkinDate < checkoutDate) {
                //@ts-ignore
                const diffTime = Math.abs(checkoutDate - checkinDate)
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
                const prices = booking.prices
                const pricesMap = Array(diffDays)
                    .fill(0)
                    .map((_, index) => {
                        const currentDate = new Date(checkinDate.toString())
                        currentDate.setDate(currentDate.getDate() + index)
                        currentDate.setHours(0)
                        const rangeIndex = prices.findIndex(
                            ({ start, end }: any) => {
                                const startDate = new Date(start as string)
                                const endDate = new Date(end as string)

                                if (
                                    currentDate >= startDate &&
                                    currentDate <= endDate
                                ) {
                                    return true
                                } else {
                                    return false
                                }
                            }
                        )

                        return rangeIndex >= 0
                            ? prices[rangeIndex].price
                            : booking.price
                    })
                    .reduce((acc, priceMap) => {
                        acc += +priceMap
                        return acc
                    }, 0)
                setVacancyPrice(pricesMap)
            }
        }
    }, [booking, checkinDate, checkoutDate, label])

    useEffect(() => {
        if (query.get('check-in')) {
            setCheckinDate(new Date(query.get('check-in') as string))
        }
        if (query.get('check-out')) {
            setCheckoutDate(new Date(query.get('check-out') as string))
        }
    }, [query])

    useEffect(() => {
        setVacancyDays()
    }, [checkinDate, checkoutDate, setVacancyDays])

    return (
        <form onSubmit={onFormSubmit}>
            <Container
                as={Padder}
                top={{ '@initial': 'xs', '@md': 'md' }}
                css={{
                    [`${RadioCheckWrapper}`]: {
                        '&.invalid': {
                            color: '#900',
                        },
                    },
                }}
            >
                <Padder top={{ '@initial': 'xs', '@md': 'md' }}>
                    <FlexWrapper
                        as={Padder}
                        left={{ '@initial': 'zero', '@md': 'doubleColWidth' }}
                        top={{ '@initial': 'xs', '@md': 'zero' }}
                        justify={{ '@initial': 'start', '@md': 'between' }}
                        direction={{ '@initial': 'column', '@md': 'row' }}
                    >
                        <StyledFormField>
                            <Input
                                ref={checkinRef}
                                type="date"
                                label={label('Check-in') + ':'}
                                //@ts-ignore
                                value={checkinDate}
                                name="check-in"
                                //@ts-ignore
                                onChange={setCheckinDate}
                                maxDate={
                                    booking.maxDate
                                        ? new Date(booking.maxDate)
                                        : undefined
                                }
                                //min date tomorrow
                                minDate={
                                    new Date(
                                        new Date().getTime() +
                                            24 * 60 * 60 * 1000
                                    )
                                }
                            />
                        </StyledFormField>
                        <StyledFormField>
                            <Input
                                ref={checkoutRef}
                                type="date"
                                label={label('Check-out') + ':'}
                                //@ts-ignore
                                value={checkoutDate}
                                name="check-out"
                                //@ts-ignore
                                onChange={setCheckoutDate}
                                maxDate={
                                    booking.maxDate
                                        ? new Date(booking.maxDate)
                                        : undefined
                                }
                                minDate={
                                    !!checkinDate
                                        ? new Date(
                                              checkinDate.getTime() +
                                                  24 * 60 * 60 * 1000
                                          )
                                        : new Date(
                                              new Date().getTime() +
                                                  48 * 60 * 60 * 1000
                                          )
                                }
                            />
                        </StyledFormField>
                    </FlexWrapper>
                    <FlexWrapper
                        as={Padder}
                        left={{ '@initial': 'zero', '@md': 'doubleColWidth' }}
                        top={{ '@initial': 'xs', '@md': 'zero' }}
                        justify={{ '@initial': 'start', '@md': 'between' }}
                        direction={{ '@initial': 'column', '@md': 'row' }}
                    >
                        <StyledFormField>
                            <div className="input-row">
                                <label>{label('Adults')}:</label>
                                <AdultsSelect
                                    ref={adultsRef}
                                    defaultValue={{
                                        value: query.get('adults') || '1',
                                        label: query.get('adults') || '1',
                                    }}
                                    maxLength={8}
                                    styles={selectStyles}
                                />
                            </div>
                        </StyledFormField>
                        <StyledFormField>
                            <div className="input-row">
                                <label>{label('Children')}:</label>
                                <AdultsSelect
                                    ref={childrenRef}
                                    defaultValue={{
                                        value: query.get('children') || '0',
                                        label: query.get('children') || '0',
                                    }}
                                    styles={selectStyles}
                                    withZero
                                    maxLength={2}
                                />
                            </div>
                        </StyledFormField>
                    </FlexWrapper>
                </Padder>
                {/* <FlexWrapper
                    as={Padder}
                    left={{ '@initial': 'zero', '@md': 'doubleColWidth' }}
                    top={{ '@initial': 'xs', '@md': 'zero' }}
                    justify={{ '@initial': 'start', '@md': 'between' }}
                    direction={{ '@initial': 'column', '@md': 'row' }}
                    align="center"
                >
                    <Text textVariant="heading4">
                        {label('vacation_price')}
                    </Text>
                    <Text
                        textVariant="heading2Bis"
                        css={{ fontFamily: '$plain', fontWeight: '$bold' }}
                    >
                        {`${(+vacancyPrice || 0).toFixed(2)}`.replace('.', ',')}{' '}
                        €
                    </Text>
                </FlexWrapper> */}
                {/* <PageTitle
                    title={label('personal_data.title')}
                    subtitle={label('personal_data.description')}
                /> */}
                <Padder top={{ '@initial': 'xs', '@md': 'md' }}>
                    <FlexWrapper
                        as={Padder}
                        left={{ '@initial': 'zero', '@md': 'doubleColWidth' }}
                        top={{ '@initial': 'xs', '@md': 'zero' }}
                        justify={{ '@initial': 'start', '@md': 'between' }}
                        direction={{ '@initial': 'column', '@md': 'row' }}
                    >
                        <StyledFormField>
                            <Input
                                type="input"
                                label={label('firstname') + ':'}
                                name="firstname"
                                required
                            />
                        </StyledFormField>
                        <StyledFormField>
                            <Input
                                type="input"
                                label={label('lastname') + ':'}
                                name="lastname"
                                required
                            />
                        </StyledFormField>
                    </FlexWrapper>
                    <FlexWrapper
                        as={Padder}
                        left={{ '@initial': 'zero', '@md': 'doubleColWidth' }}
                        top={{ '@initial': 'xs', '@md': 'zero' }}
                        justify={{ '@initial': 'start', '@md': 'between' }}
                        direction={{ '@initial': 'column', '@md': 'row' }}
                    >
                        <StyledFormField>
                            <Input
                                type="input"
                                label={label('telephone') + ':'}
                                name="telephone"
                            />
                        </StyledFormField>
                        <StyledFormField>
                            <Input
                                type="email"
                                label={label('email') + ':'}
                                name="email"
                                required
                            />
                        </StyledFormField>
                    </FlexWrapper>
                    <div>
                        <StyledFormField>
                            <Textarea
                                placeholder={label('notes')}
                                name="notes"
                            />
                        </StyledFormField>
                    </div>
                    <FlexWrapper
                        as={Padder}
                        left={{ '@initial': 'zero', '@md': 'doubleColWidth' }}
                        top={{ '@initial': 'md', '@md': 'zero' }}
                        justify={{ '@initial': 'start', '@md': 'between' }}
                        direction={{ '@initial': 'column', '@md': 'row' }}
                    >
                        <div>
                            <RadioCheckWrapper>
                                <input
                                    type="checkbox"
                                    value={label('privacy')}
                                    name="privacy"
                                    required
                                    onInvalid={(e) => {
                                        //@ts-ignore
                                        e.target.parentElement?.classList.add(
                                            'invalid'
                                        )
                                        setResponse(label('privacy_error'))
                                    }}
                                    onInput={(e) => {
                                        //@ts-ignore
                                        e.target.parentElement?.classList.remove(
                                            'invalid'
                                        )
                                        setResponse(undefined)
                                    }}
                                />
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: label('privacy'),
                                    }}
                                />
                            </RadioCheckWrapper>
                        </div>
                        <FlexWrapper
                            direction="column"
                            align={{ '@initial': 'center', '@md': 'end' }}
                        >
                            <Button variant="blueFill">
                                {isSending
                                    ? label('sending')
                                    : label('send_vacancy_request')}
                            </Button>
                            {response && !isSending && (
                                <Text
                                    textVariant="plain2"
                                    style={{ marginTop: '15px' }}
                                >
                                    {response}
                                </Text>
                            )}
                        </FlexWrapper>
                    </FlexWrapper>
                </Padder>
            </Container>
        </form>
    )
}

export default BookingForm
