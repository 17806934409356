import { styled } from 'src/styles/stitches.config'

export const StyledLogo = styled('div', {
    position: 'relative',
    width: '48vw',
    img: {
        maxWidth: '100%',
    },
    '@md': {
        width: '15rem',
    },
})
