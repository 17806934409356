import { styled } from 'src/styles/stitches.config'

export const StyledGallery = styled('div', {
    position: `relative`,
    '.swiper-slide': {
        width: 'auto',
        transition: 'opacity .3s linear',
        height: '300px',

        '> img': {
            width: 'auto',
            maxWidth: '80vw',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
        },
        '@md': {
            height: '60vh',
            '> img': {
                width: 'auto',
                maxWidth: '40vw',
            },
        },
    },
})

export const StyledGalleryWrapper = styled('div', {
    position: 'relative',
    width: '100%',
})

export const StyledNavButton = styled('button', {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    background: 'transparent',
    zIndex: 10,
    svg: {
        fontSize: '3rem',
        color: '$green',
    },
    '@mdDown': {
        svg: {
            fontSize: '2.5rem',
        },
    },
    variants: {
        direction: {
            left: {
                left: '$xs',
            },
            right: {
                right: '$xs',
            },
        },
    },
})
