import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Text from 'src/components/atoms/Text/Text'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import { getMainMenu } from 'src/selectors/settings'
import { StyledMainMenutem } from './MainMenu.style'

const MainMenu: React.FC = (props) => {
    const mainMenu = useSelector(getMainMenu)
    return (
        <FlexWrapper as={Padder} left="sm">
            {mainMenu?.items.map(({ ID, title, url }) => (
                <StyledMainMenutem
                    as={Text}
                    key={ID}
                    textVariant="plain2"
                    color="black"
                    textTransform="uppercase"
                >
                    <Link to={url}>{title}</Link>
                </StyledMainMenutem>
            ))}
        </FlexWrapper>
    )
}

export default MainMenu
