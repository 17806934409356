import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Image from 'src/components/atoms/Image/Image'
import RichText from 'src/components/atoms/RichText/RichText'
import Text from 'src/components/atoms/Text/Text'
import Container from 'src/components/_layout/Container/Container'
import Padder from 'src/components/_layout/Padder/Padder'
import { setHasTitle } from 'src/state/layout'
import { CmsImage, CmsLink } from 'src/types'
import {
    StyledImageContent,
    StyledLinkWrapper,
    StyledPageTitle,
    StyledPageTitleContent,
} from './PageTitle.style'
import { link } from 'fs'
import { Link } from 'react-router-dom'

type PageTitleProps = {
    title: string
    subtitle?: string
    image?: CmsImage
    link?: CmsLink
}
const PageTitle: React.FC<PageTitleProps> = ({
    title,
    subtitle,
    image,
    link,
}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        if (!!image) {
            dispatch(setHasTitle(true))
        }

        return () => {
            dispatch(setHasTitle(false))
        }
    }, [dispatch, image])

    return (
        <StyledPageTitle className="page-title">
            {!!image && (
                <StyledImageContent>
                    <Image src={image?.url} />
                </StyledImageContent>
            )}
            <StyledPageTitleContent plain={!image}>
                <Padder as={Container} top="md">
                    <RichText
                        align="center"
                        textVariant={'heading1'}
                        htmlString={title}
                    />
                    {subtitle && (
                        <RichText
                            htmlString={subtitle}
                            textVariant={!!image ? 'base3' : 'plain2'}
                            align="center"
                        />
                    )}
                </Padder>
            </StyledPageTitleContent>
            {link && (
                <StyledLinkWrapper>
                    <Text
                        as={link.url === '#' ? 'div' : Link}
                        to={link.url}
                        target={link.target}
                        textVariant="plain"
                        css={{
                            paddingTop: '$xs',
                            fontFamily: '$secondary',
                            textTransform: 'uppercase',
                        }}
                    >
                        {link.title}
                    </Text>
                </StyledLinkWrapper>
            )}
        </StyledPageTitle>
    )
}

export default PageTitle
