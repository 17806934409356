import { createStitches } from '@stitches/react'

import styleUtils from './styleUtils'

export const styleConstants = {
    colWidth: 4.16666666667,
}

export const breakpoints: Record<string, number> = {
    xxxs: 0,
    xs: 576,
    sm: 768,
    md: 1024,
    lg: 1200,
    xl: 1440,
    xxl: 1600,
}

export const {
    styled,
    css,
    globalCss,
    keyframes,
    getCssText,
    theme,
    createTheme,
    config,
} = createStitches({
    theme: {
        fonts: {
            primary: 'Goudy Old Style',
            secondary: 'ArialMT',
            plain: 'Goudy Old Style',
        },
        colors: {
            green: '#a3b194',
            black: '#333333',
            white: '#ffffff',
            whiteSmoke: '#ede5e2',
            background: '#ede5e2',
        },
        fontWeights: {
            light: 100,
            normal: 300,
            plain: 'normal',
            bold: 'bold',
        },
        sizes: {
            colWidth: `${100 / 24}vw`,
            xxs: '0.75rem',
            xs: '1rem',
            sm: '2rem',
            md: '3rem',
            lg: '4.5rem',
            xl: '10rem',
            xxl: '12rem',
            xxxl: '13rem',
            zero: '0rem',
        },
        space: {
            colWidth: `${100 / 24}vw`,
            doubleColWidth: `${100 / 12}vw`,
            xxs: '0.75rem',
            xs: '1rem',
            sm: '2rem',
            md: '3rem',
            lg: '4.5rem',
            xl: '10rem',
            xxl: '12rem',
            xxxl: '13rem',
            zero: '0rem',
        },
    },
    media: {
        ...Object.fromEntries([
            ...Object.keys(breakpoints).map((key: string) => [
                key,
                `(min-width:${breakpoints[key]}px)`,
            ]),
            ...Object.keys(breakpoints).map((key: string) => [
                `${key}Down`,
                `(max-width:${breakpoints[key] - 1}px)`,
            ]),
        ]),
        hover: '(hover: hover)',
    },
    utils: {
        ...styleUtils,
    },
})

export const globalStyles = globalCss({
    body: {
        fontFamily:
            'Goudy Old Style, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
        fontWeight: '$normal',
        color: '$black',
    },

    '.App': {
        height: '100vh',
        backgroundColor: '$whiteSmoke',
        overflow: 'hidden',
    },
    '.with-line': {
        textTransform: 'uppercase',
        display: 'flex',
        gap: '20px',
        alignItems: 'center',
        '&::after': {
            width: `${1 * styleConstants.colWidth}vw`,
            height: '2px',
            content: '',
            backgroundColor: '$black',
        },
    },
    '.with-arrow': {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        color: '$black',
        textDecoration: 'none',
        textTransform: 'uppercase',
        fontSize: '16px',
        '&::after': {
            content: '',
            width: '16px',
            height: '16px',
            backgroundImage:
                'url(data:image/svg+xml;base64,PHN2ZyBzdHJva2U9ImN1cnJlbnRDb2xvciIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIwIiB2aWV3Qm94PSIwIDAgMjQgMjQiIGhlaWdodD0iMWVtIiB3aWR0aD0iMWVtIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJNMTcgOGw0IDRtMCAwbC00IDRtNC00SDMiPjwvcGF0aD48L3N2Zz4K)',
        },
    },
    '.hidden-xs': {
        '@smDown': {
            display: 'none !important',
        },
    },
    '.hidden-sm': {
        '@mdDown': {
            display: 'none !important',
        },
    },
    '.visible-sm': {
        display: 'none !important',
        '@mdDown': {
            display: 'block !important',
        },
    },
})
