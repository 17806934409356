import { styled } from 'src/styles/stitches.config'

export const StyledImageWrapper = styled('div', {
    position: 'relative',
    img: {
        objectFit: 'cover',
        width: '100%',
    },
})

export const StyledBannerContent = styled('div', {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '$white',
    padding: '$sm $md',
    border: 'solid 2px $white',

    '@mdDown': {
        width: '70%',
        padding: '$xs',
        textAlign: 'center',
    },
})
