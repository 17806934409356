import { Link } from 'react-router-dom'
import ImageReveal from 'src/components/atoms/ImageReveal/ImageReveal'
import Text from 'src/components/atoms/Text/Text'
import Container from 'src/components/_layout/Container/Container'
import { CmsImage, CmsLink } from 'src/types'
import { StyledBannerContent, StyledImageWrapper } from './Banner.style'

export type BannerProps = {
    image: CmsImage
    small?: boolean
    fullWidth?: boolean
}

const Banner: React.FC<BannerProps> = ({
    image: { url, alt },
    small,
    fullWidth,
}) => {
    const style = fullWidth ? { paddingLeft: 0, paddingRight: 0 } : {}
    return url ? (
        <Container css={{ position: 'relative', ...style }} small={small}>
            <StyledImageWrapper>
                <ImageReveal src={url} alt={alt} />
            </StyledImageWrapper>
        </Container>
    ) : null
}

export default Banner
