import { useResponsive } from 'ahooks'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Container from 'src/components/_layout/Container/Container'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import RichText from 'src/components/atoms/RichText/RichText'
import Text from 'src/components/atoms/Text/Text'
import { getFooter } from 'src/selectors/settings'
import { MenuItemType } from 'src/state/settings/types'
import { StyledFooter } from './Footer.style'

type FooterProps = {
    disabled?: boolean
}

const Footer: React.FC<FooterProps> = ({ disabled }) => {
    const footer = useSelector(getFooter)

    const responsiveInfo = useResponsive()

    const getLinkComponent = useCallback((item: MenuItemType) => {
        const { url } = item
        const targetValue = url.startsWith('http') ? '_blank' : undefined
        const isSpecialLink = url.startsWith('mailto') || url.startsWith('tel')
        const isA = isSpecialLink || targetValue === '_blank'
        const to = isA
            ? {
                  href: url,
              }
            : {
                  to: url,
              }
        return (
            <Text
                key={item.ID}
                as={isA ? 'a' : Link}
                {...to}
                target={targetValue}
                textVariant="plain"
                align={{
                    '@initial': 'center',
                    '@md': 'left',
                }}
            >
                {item.title}
            </Text>
        )
    }, [])

    return (
        <StyledFooter>
            <Container
                as={FlexWrapper}
                direction={{ '@initial': 'column', '@md': 'row' }}
                align="center"
                justify={{ '@initial': 'start', '@md': 'between' }}
            >
                <FlexWrapper
                    as={Padder}
                    direction={{ '@initial': 'column', '@md': 'row' }}
                    justify={{ '@initial': 'center', '@md': 'between' }}
                    align="center"
                    left={{ '@initial': 'zero', '@md': 'lg' }}
                    top={{ '@initial': 'xs', '@md': 'zero' }}
                    css={{ p: { marginBottom: 0 } }}
                >
                    {/* {footer.logo && (
                        <FooterLogo>
                            <Image src={footer.logo?.url} />
                        </FooterLogo>
                    )} */}
                    {responsiveInfo.md ? (
                        <>
                            <RichText
                                htmlString={footer.description1 || ''}
                                textVariant="plain"
                                //@ts-ignore
                                align={{ '@initial': 'center', '@md': 'left' }}
                            />
                            <RichText
                                htmlString={footer.description2 || ''}
                                textVariant="plain"
                                //@ts-ignore
                                align={{ '@initial': 'center', '@md': 'left' }}
                            />
                        </>
                    ) : (
                        <RichText
                            htmlString={footer.descriptionMobile || ''}
                            textVariant="plain"
                            //@ts-ignore
                            align={{ '@initial': 'center', '@md': 'left' }}
                        />
                    )}
                </FlexWrapper>
                <FlexWrapper
                    as={Padder}
                    direction={{ '@initial': 'column', '@md': 'row' }}
                    left={{ '@initial': 'zero', '@md': 'sm' }}
                    top={{ '@initial': 'xxs', '@md': 'zero' }}
                    css={{
                        '@mdDown': {
                            width: '100%',
                        },
                    }}
                >
                    {footer?.menu1?.items?.map(getLinkComponent)}
                </FlexWrapper>
            </Container>
        </StyledFooter>
    )
}

export default Footer
