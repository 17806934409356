import { styled } from 'src/styles/stitches.config'

export const StyledLanguageSwitcher = styled('div', {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',

    width: '10rem',
    '@md': {
        width: '15rem',
    },
})

export const StyledSecondLang = styled('div', {
    position: 'absolute',
    top: 'calc(100% + 20px)',
    left: 0,
    width: '100%',
})

export const Divider = styled('span', {
    display: 'inline-block',
    verticalAlign: 'middle',
    height: '1.4em',
    width: '1px',
    backgroundColor: '$black',
    '.not-found &': {
        backgroundColor: '$black',
    },
})
