import Banner, { BannerProps } from '../Banner/Banner'
import Content, { ContentProps } from '../Content/Content'

type ImageLaunchProps = BannerProps & ContentProps

const ImageLaunch: React.FC<ImageLaunchProps> = (props) => (
    <>
        <Banner {...props} />
        <Content {...props} />
    </>
)

export default ImageLaunch
