import { useRef } from 'react'
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa'

import Image from 'src/components/atoms/Image/Image'
import { CmsImage } from 'src/types'
import SwiperInstance from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
    StyledGallery,
    StyledGalleryWrapper,
    StyledNavButton,
} from './Gallery.style'

type GalleryProps = {
    images: Array<CmsImage>
}

const Gallery: React.FC<GalleryProps> = ({ images }) => {
    const swiperRef = useRef<SwiperInstance>()
    return (
        <StyledGalleryWrapper>
            <StyledGallery
                as={Swiper}
                slidesPerView="auto"
                spaceBetween={50}
                loop
                onSwiper={(swiper) => {
                    swiperRef.current = swiper
                }}
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <Image src={image.url} alt={image.alt} />
                    </SwiperSlide>
                ))}
            </StyledGallery>
            <StyledNavButton
                direction="left"
                onClick={() => swiperRef.current?.slidePrev()}
            >
                <FaChevronCircleLeft />
            </StyledNavButton>
            <StyledNavButton
                direction="right"
                onClick={() => swiperRef.current?.slideNext()}
            >
                <FaChevronCircleRight />
            </StyledNavButton>
        </StyledGalleryWrapper>
    )
}

export default Gallery
