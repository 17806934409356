import { styled } from 'src/styles/stitches.config'
import pattern from 'src/assets/images/pattern.png'
export const StyledPageTitle = styled('div', {
    position: 'relative',
    '&:nth-last-child(2)': {
        marginTop: '-$xxxl!important',
    },
    '@mdDown': {
        '&:nth-last-child(2)': {
            marginTop: '0!important',
        },
    },
})

export const StyledImageContent = styled('div', {
    position: 'relative',
    height: '82vh',
    backgroundColor: '$green',
    overflow: 'hidden',
    minHeight: '500px',
    '> img': {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
    },
    '@mdDown': {
        height: '80vh',
        minHeight: '0px',
    },
})

export const StyledPageTitleContent = styled('div', {
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    margin: 'auto',
    transform: 'translateY(-50%)',
    color: '$background',

    variants: {
        plain: {
            true: {
                position: 'relative',
                top: 0,
                left: 0,
                right: 0,
                margin: 'auto',
                transform: 'none',
                color: '$green',

                [`.page-title:first-child &`]: {
                    paddingTop: '$xxl',
                },
                [`.page-title:nth-last-child(2) &`]: {
                    paddingTop: 'calc($xxxl + $xxxl + $sm)',
                    backgroundImage: `url(${pattern})`,
                    backgroundSize: '100% auto',
                    backgroundRepeat: 'no-repeat',
                    '@mdDown': {
                        paddingTop: '$lg',
                        backgroundSize: 'auto 150px',
                    },
                },
            },
        },
    },
})

export const StyledLinkWrapper = styled('div', {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    width: '100%',
    height: 'auto',
    margin: 'auto',
    paddingTop: '$sm',
    zIndex: 2,
    textAlign: 'center',
    [`.page-title:nth-last-child(2) &`]: {
        position: 'relative',
        top: 0,
    },
    '&::before': {
        display: 'block',
        height: '$xxl',
        width: '1px',
        margin: '0 auto',
        backgroundColor: '$black',
        content: '',
    },
    [`.page-title:nth-last-child(2) &::before`]: {
        height: '$xl',
    },

    '@mdDown': {
        [`.page-title:first-child &`]: {
            display: 'none',
        },
    },
})
