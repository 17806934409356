import { useResponsive } from 'ahooks'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import Button from 'src/components/atoms/Button/Button'
import Logo from 'src/components/atoms/Logo/Logo'
import Text from 'src/components/atoms/Text/Text'
import LanguageSwitcher from 'src/components/molecules/LanguageSwitcher/LanguageSwitcher'
import SiteLinks from 'src/components/molecules/SiteLinks/SiteLinks'
import Container from 'src/components/_layout/Container/Container'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import { getLayerById } from 'src/selectors/layers'
import { toggleLayer } from 'src/state/layers'
import { selectHasTitle } from 'src/state/layout'
import { RootState } from 'src/store'
import MainMenu from '../MainMenu/MainMenu'
import { HiOutlineMenuAlt3, HiX } from 'react-icons/hi'

import { StyledHeader, StyledHeaderContent } from './Header.style'
const Header: React.FC = (props) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const hasTitle = useSelector(selectHasTitle)
    const responsiveInfo = useResponsive()
    const menuLayer = useSelector((state: RootState) =>
        getLayerById(state, 'mainMenu')
    )

    const toggleMenu = useCallback(() => {
        if (menuLayer) {
            dispatch(
                toggleLayer({
                    layerId: menuLayer.id,
                })
            )
        }
    }, [dispatch, menuLayer])

    useEffect(() => {
        if (hasTitle) {
            document.querySelector('#header')?.classList.add('light')
        } else {
            document.querySelector('#header')?.classList.remove('light')
        }
    }, [hasTitle, location.pathname])

    return (
        <StyledHeader id="header">
            <StyledHeaderContent open={menuLayer?.isActive}>
                <FlexWrapper justify="between" align="end">
                    <Logo />
                    {responsiveInfo.md && (
                        <>
                            <MainMenu />
                            <LanguageSwitcher />
                        </>
                    )}
                    {!responsiveInfo.md && (
                        <div>
                            <button onClick={toggleMenu}>
                                {menuLayer?.isActive ? (
                                    <HiX fontSize="2rem" color="#333333" />
                                ) : (
                                    <HiOutlineMenuAlt3
                                        fontSize="2rem"
                                        color="#333333"
                                    />
                                )}
                            </button>
                        </div>
                    )}
                </FlexWrapper>
            </StyledHeaderContent>
        </StyledHeader>
    )
}

export default Header
