import { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useResponsive } from 'ahooks'
import { motion } from 'framer-motion'
import { getLayerById } from 'src/selectors/layers'
import { createLayer, hideLayer } from 'src/state/layers'
import { RootState } from 'src/store'

import { Link } from 'react-router-dom'
import Text from 'src/components/atoms/Text/Text'
import SiteLinks from 'src/components/molecules/SiteLinks/SiteLinks'
import Container from 'src/components/_layout/Container/Container'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import { getSettings } from 'src/selectors/settings'
import { MenuPanel, SocialWrapper, StyledLayerMenu } from './LayerMenu.style'

const LAYER_ID = 'mainMenu'

const LayerMenu: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
    const dispatch = useDispatch()
    const responsiveInfo = useResponsive()
    const layer = useSelector((state: RootState) =>
        getLayerById(state, LAYER_ID)
    )

    const islightRef = useRef(false)

    const { menu } = useSelector(getSettings)

    const menuLayer = useSelector((state: RootState) =>
        getLayerById(state, 'mainMenu')
    )
    const closeMenu = useCallback(() => {
        if (menuLayer && menuLayer.isActive) {
            dispatch(
                hideLayer({
                    layerId: menuLayer.id,
                })
            )
        }
    }, [dispatch, menuLayer])

    useEffect(() => {
        dispatch(
            createLayer({
                id: LAYER_ID,
                isActive: false,
            })
        )
    }, [])

    useEffect(() => {
        if (menuLayer?.isActive) {
            setTimeout(() => {
                islightRef.current =
                    document
                        .querySelector('#header')
                        ?.classList.contains('light') || false
                document.querySelector('#header')?.classList.add('light')
            }, 100)
        } else {
            setTimeout(() => {
                if (!islightRef.current) {
                    document.querySelector('#header')?.classList.remove('light')
                }
            }, 400)
        }
    }, [menuLayer?.isActive])

    return menu ? (
        <StyledLayerMenu
            as={motion.div}
            animate={{
                width: layer?.isActive ? '100vw' : '0vw',
            }}
            transition={{
                ease: 'easeOut',
                duration: 0.8,
                delay: layer?.isActive ? 0 : 0.4,
            }}
        >
            <motion.div
                animate={{
                    opacity: layer?.isActive ? 1 : 0,
                }}
                transition={{
                    delay: layer?.isActive ? 0.25 : 0,
                    duration: 0.25,
                }}
                style={{ height: '100%' }}
            >
                <FlexWrapper
                    css={{
                        height: '100%',
                        '@mdDown': {
                            padding: '120px 0 50px',
                            height: 'auto',
                        },
                    }}
                    direction="column"
                    justify={{ '@initial': 'start', '@md': 'center' }}
                >
                    <FlexWrapper
                        as={Container}
                        direction={{ '@initial': 'column', '@md': 'row' }}
                        justify={{ '@initial': 'start', '@md': 'between' }}
                    >
                        {menu.map(
                            ({ items }, index) =>
                                items && (
                                    <MenuPanel key={index} big={index === 1}>
                                        <Padder
                                            top={{
                                                '@initial': 'xs',
                                                '@md': 'md',
                                            }}
                                            css={{
                                                display: 'inline-block',
                                                '@md': { textAlign: 'left' },
                                            }}
                                        >
                                            <Padder top="xs" as="ul">
                                                {items.map(
                                                    ({ ID, url, title }) => (
                                                        <li key={ID}>
                                                            <Text
                                                                as={Link}
                                                                to={url}
                                                                textVariant="layerMenu"
                                                                onClick={
                                                                    closeMenu
                                                                }
                                                            >
                                                                {title}
                                                            </Text>
                                                        </li>
                                                    )
                                                )}
                                            </Padder>
                                        </Padder>
                                    </MenuPanel>
                                )
                        )}
                    </FlexWrapper>
                    {!responsiveInfo.md && (
                        <SocialWrapper>
                            <SiteLinks />
                        </SocialWrapper>
                    )}
                </FlexWrapper>
            </motion.div>
        </StyledLayerMenu>
    ) : null
}

export default LayerMenu
