import Container from 'src/components/_layout/Container/Container'
import RichText from 'src/components/atoms/RichText/RichText'
import Revealer from 'src/components/helpers/Revealer/Revealer'

export type ContentProps = {
    description: string
}

const Content: React.FC<ContentProps> = ({ description }) => {
    return (
        <Container small>
            <Revealer style={{ width: '100%' }}>
                <RichText
                    htmlString={description}
                    textVariant="base"
                    css={{
                        '@md': {
                            textAlign: 'center',
                        },
                    }}
                />
            </Revealer>
        </Container>
    )
}

export default Content
