import { useResponsive } from 'ahooks'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { matchPath } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'

import WebsiteContainer from 'src/components/_layout/WebsiteContainer'
import GlobalScroller from 'src/components/helpers/GlobalScroller/GlobalScroller'

import { getSettings } from 'src/selectors/settings'
import Whatsapp from './components/molecules/Whatsapp/Whatsapp'

import Header from './components/organisms/Header/Header'
import LayerMenu from './components/organisms/LayerMenu/LayerMenu'
import Popup from './components/organisms/Popup/Popup'
import { BASEPATH } from './constants'
import ScrollbarProvider from './context/scrollbar'
import RouterComponent from './router'
import { fetchMenu, fetchSettings } from './state/settings'

export default function App() {
    const dispatch = useDispatch()
    const { ready } = useSelector(getSettings)
    useEffect(() => {
        const match = matchPath(window.location.pathname, {
            path: `${BASEPATH}/:lang(it|en)/:page?`,
        })
        //@ts-ignore
        const lang = match?.params?.lang
        dispatch(fetchSettings(lang || ''))
        dispatch(fetchMenu(lang || ''))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const responsiveInfo = useResponsive()

    return ready ? (
        <>
            <div className="App">
                <Popup />
                <Router>
                    <ScrollbarProvider>
                        <Header />
                        {!responsiveInfo.md && <LayerMenu />}
                        {/* <Whatsapp /> */}
                        <GlobalScroller>
                            <WebsiteContainer>
                                <RouterComponent />
                            </WebsiteContainer>
                        </GlobalScroller>
                    </ScrollbarProvider>
                </Router>
            </div>
        </>
    ) : null
}
