import { forwardRef, MutableRefObject, useEffect, useRef } from 'react'
import DatePicker from 'react-date-picker'
import Padder from 'src/components/_layout/Padder/Padder'
import FieldError from '../FieldError/FieldError'
import { StyledDatePicker, StyledInput } from './Input.style'
import { useResponsive } from 'ahooks'

type InputProps = React.HTMLProps<HTMLInputElement> & {
    error?: any
    required?: any
    value?: string | Date
    onChange?: Function
    minDate?: Date
    maxDate?: Date
}

const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            type,
            placeholder,
            name,
            error,
            required,
            label,
            value,
            onChange,
            minDate,
            maxDate,
        },
        ref
    ) => {
        const fallbackRef = useRef() as MutableRefObject<HTMLInputElement>
        const responsiveInfo = useResponsive()
        const inputRef =
            (ref as MutableRefObject<HTMLInputElement>) || fallbackRef
        useEffect(() => {
            if (!!value && inputRef.current) {
                inputRef.current.value = value as string
            }
        }, [inputRef, value])

        return (
            <>
                <StyledInput as={Padder} top="xs">
                    {label && <label>{label}</label>}

                    {type !== 'date' ? (
                        <input
                            type={type}
                            name={name}
                            placeholder={placeholder}
                            ref={inputRef}
                            required={required}
                            onChange={onChange}
                        />
                    ) : (
                        <StyledDatePicker>
                            <DatePicker
                                value={value as Date}
                                /* @ts-ignore */
                                onChange={onChange}
                                minDate={minDate}
                                maxDate={maxDate}
                                disableCalendar={!responsiveInfo.md}
                            />
                        </StyledDatePicker>
                    )}
                </StyledInput>
                {error && <FieldError errorType={error.type} />}
            </>
        )
    }
)

export default Input
